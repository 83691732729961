// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getClass = createAsyncThunk('student/classes/get', async (payload, { dispatch, getState }) => {
  const response = await useApi.studentClassesGet(payload)
  return response.data
})

export const getClassWithLesson = createAsyncThunk('student/classes/getWithLEsson', async (payload, { dispatch, getState }) => {
  const response = await useApi.studentClassesGet(payload.classId, payload.lessonId)
  return response.data
})

export const addAnswerApi = createAsyncThunk('student/classes/addAnswer', async (payload, { dispatch, getState }) => {
  const response = await useApi.studentClassAddAnswer(payload.classId, payload.exerciseId, payload.isMultipleAnswers, payload.answer)
  return response.data
})

export const getVocabulary = createAsyncThunk('student/classes/vocabulary', async (payload, { dispatch, getState }) => {
  const response = await useApi.studentVocabularyList(getState().studentClass.vocabulary.params)
  return response
})

export const studentClassSlice = createSlice({
  name: 'studentClass',
  initialState: {
    usersOnline: [],
    selectedSection: null,
    isLoading: false,
    class: null,
    myAnswers: {},
    error: null,
    total: 0,
    params: {},
    lesesonSettings: [],
    vocabulary: {
      params: {
        itemsPerPage: 10,
        currentPage: 1,
        q: '',
        isLoadMore: false
      },
      items: [],
      isLoading: false,
      isMorePages: false
    }
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
    changeVocabulary(state, action) {
      state.vocabulary = {
        ...state.vocabulary,
        ...action.payload
      }
    },
    addUsersOnline(state, action) {
      state.usersOnline = [
        ...state.usersOnline,
        ...action.payload,
      ]
    },
    removeUsersOnline(state, action) {
      state.usersOnline = [
        ...state.usersOnline.filter(uo => !action.payload.includes(uo))
      ]
    },
    setUsersOnline(state, action) {
      state.usersOnline = [...action.payload]
    },
    setActiveCourseLessonSection(state, action) {
      state.selectedSection = action.payload
    },
    addAnswer(state, action) {
      if (!action.payload.isMultipleAnswers) {
        state.myAnswers[action.payload.exerciseId] = action.payload.answer
      } else {
        if (state.myAnswers[action.payload.exerciseId]) {
          if (action.payload.isMultipleTest) {
            state.myAnswers[action.payload.exerciseId] = state.myAnswers[action.payload.exerciseId].map(ma => {
              if (ma.questionIndex === action.payload.answer.questionIndex) {
                ma.answer.push(action.payload.answer.answer)
              }

              return ma
            })
          } else {
            state.myAnswers[action.payload.exerciseId].push(action.payload.answer)
          }
        } else {
          state.myAnswers[action.payload.exerciseId] = [action.payload.answer]
        }
      }
    },
    clearExerciseAnswersState(state, action) {
      state.myAnswers[action.payload.exerciseId] = undefined
    },
    changeExerciseSetting(state, action) {
      
      const isExerciseExist = state.lesesonSettings.find(
        ls => parseInt(ls.classId) === parseInt(action.payload.classId) &&
          parseInt(ls.lessonId) === parseInt(action.payload.lessonId) &&
          parseInt(ls.exerciseId) === parseInt(action.payload.exerciseId) &&
          ls.key === action.payload.params.key
      )

      if (isExerciseExist) {
        state.lesesonSettings = state.lesesonSettings.map((ls) => {
          if (
            parseInt(ls.classId) === parseInt(action.payload.classId) &&
            parseInt(ls.lessonId) === parseInt(action.payload.lessonId) &&
            parseInt(ls.exerciseId) === parseInt(action.payload.exerciseId) &&
            ls.key === action.payload.params.key
          ) {
            ls.value = parseInt(action.payload.params.value)
          }

          return ls
        })
      } else {
        state.lesesonSettings = [
          ...state.lesesonSettings,
          {
            classId: action.payload.classId,
            lessonId: action.payload.lessonId,
            exerciseId: action.payload.exerciseId,
            key: action.payload.params.key,
            value: action.payload.params.value
          }
        ]
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClass.pending, (state) => {
      state.isLoading = true
      state.error = null
    })
    builder.addCase(getClass.fulfilled, (state, action) => {
      state.class = action.payload.data.class
      state.myAnswers = action.payload.data.answers
      state.lesesonSettings = action.payload.data.lesesonSettings
      state.error = !action.payload.data.canAccessToClass ? 'На жаль доступ до цього класу обмежений' : null

      if (state.class.courseLesson) {
        state.class.courseLesson.sections = state.class.courseLesson.sections.sort((a, b) => parseInt(a.position) - parseInt(b.position))

        if (!state.selectedSection) {
          state.selectedSection = state.class.courseLesson.sections.length > 0 ? state.class.courseLesson.sections[0].id : null
        } else {
          const isSectionExist = state.class.courseLesson.sections.length > 0 && state.class.courseLesson.sections.find(s => s.id === state.selectedSection) !== undefined

          if (!isSectionExist) {
            state.selectedSection = state.class.courseLesson.sections.length > 0 ? state.class.courseLesson.sections[0].id : null
          }
        }
      }
      
      state.isLoading = false
    })
    builder.addCase(getClass.rejected, (state, action) => {
      state.error = 'На жаль доступ до цього класу обмежений'
      state.isLoading = false
    })
    builder.addCase(getClassWithLesson.pending, (state) => {
      state.isLoading = true
      state.error = null
    })
    builder.addCase(getClassWithLesson.fulfilled, (state, action) => {
      state.class = action.payload.data.class
      state.lesesonSettings = action.payload.data.lesesonSettings
      state.myAnswers = action.payload.data.answers
      state.error = !action.payload.data.canAccessToClass ? 'На жаль доступ до цього класу обмежений' : null

      if (state.class.courseLesson) {
        state.class.courseLesson.sections = state.class.courseLesson.sections.sort((a, b) => parseInt(a.position) - parseInt(b.position))

        if (!state.selectedSection) {
          state.selectedSection = state.class.courseLesson.sections.length > 0 ? state.class.courseLesson.sections[0].id : null
        } else {
          const isSectionExist = state.class.courseLesson.sections.length > 0 && state.class.courseLesson.sections.find(s => s.id === state.selectedSection) !== undefined

          if (!isSectionExist) {
            state.selectedSection = state.class.courseLesson.sections.length > 0 ? state.class.courseLesson.sections[0].id : null
          }
        }
      }
      
      state.isLoading = false
    })
    builder.addCase(getClassWithLesson.rejected, (state, action) => {
      state.error = 'На жаль доступ до цього класу обмежений'
      state.isLoading = false
    })
    builder.addCase(getVocabulary.pending, (state) => {
      state.vocabulary.isLoading = true
    })
    builder.addCase(getVocabulary.fulfilled, (state, action) => {
      if (state.vocabulary.params.isLoadMore) {
        state.vocabulary.items = state.vocabulary.items.concat(action.payload.data.data)
        state.vocabulary.params.isLoadMore = false
      } else {
        state.vocabulary.items = action.payload.data.data
      }

      state.vocabulary.isLoading = false
      state.vocabulary.isMorePages = parseInt(action.payload.data._meta.pagination.total) > parseInt(state.vocabulary.params.itemsPerPage) * parseInt(state.vocabulary.params.currentPage)
    })
  }
})

export const { addUsersOnline, removeUsersOnline, setUsersOnline, changeParams, setActiveCourseLessonSection, addAnswer, changeExerciseSetting, changeVocabulary, clearExerciseAnswersState } = studentClassSlice.actions

export default studentClassSlice.reducer